/* App.css */

body {
    font-family: 'Roboto', sans-serif;
    color: #333;
    background-color: aliceblue;
}

.logo {
    font-family: 'Playfair Display', serif;
    color: #2c3e50;
    font-weight: 700;
    font-size: 48px;
    text-align: center;
    margin: 20px 0;
}

.header {
    text-align: center;
}

.content {
    display: flex;         /* Use flexbox to align children */
    justify-content: center; /* Center children horizontally in the container */
    align-items: center;     /* Center children vertically in the container */
    flex-direction: column;
    margin: 0 auto; /* This will center the block if it has a set width */
}

.content h2 {
    margin: 0px;
}

.button {
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    margin: 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #4CAF50;
}

.button.reset-button {
    background-color: #3639f4;
}

.button.record-button.recording {
    background-color: #ff4d4d;
}

.button.disabled,
.button:disabled {
  background-color: #ccc; /* Gray out the button */
  color: #666; /* Darker text to indicate it's disabled */
  border: 1px solid #999; /* Optional: Add a border to show it's not clickable */
  cursor: not-allowed; /* Change cursor to indicate the button is disabled */
  opacity: 0.5; /* Fade the button out */
}

/* Additional styling for when the button is in the recording state */
.recording:not(.disabled) {
  background-color: #ff4d4d;
}

.text-container {
    position: relative; /* Ensure the download button is positioned relative to this container */
    width: 80%;
    max-width: 80%;    
    margin: 5px 0;
    padding: 5px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
.text-container h2 {
    background-color: #a6c0d0;
    margin-top: 0;
    color: #2c3e50;
    padding: 5px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.button.download-button {
    position: absolute;
    right: -15px;
    top: -15px;
    background-color: orange;
    color: #2c3e50;
}

.text-content {
    white-space: pre-wrap; /* Preserves spaces and line breaks */
    /* font-family: 'Courier New', Courier, monospace; */
    color: #555;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ddd;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow-y: auto; /* Adds a scrollbar if content is too long */
}

.processing-indicator {
    color: #888;
    font-style: italic;
}

.radio-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.radio-option {
    display: flex;
    align-items: center;
}

input[type="radio"]:checked + label {
    font-weight: bold;
    font-style: italic;
}

label {
    cursor: pointer;
}
